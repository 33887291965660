<template>
    <section data-aos="zoom-in" data-aos-once="true" data-aos-delay="100" data-aos-offset="200" data-aos-duration="400"
        class="bg-white dark:bg-gray-900 max-w-7xl 3xl:max-w-8xl mx-auto">
        <div class="container pb-12 mx-auto">
            <div class="grid grid-cols-1 gap-12 mt-10 md:grid-cols-1 lg:grid-cols-3">
                <a href="mailto:info@ngsi.de">
                    <div
                        class="group/item hover:dark:bg-gray-700 hover:bg-gray-300 hover:scale-110 py-3 rounded-lg text-center justify-center flex flex-col items-center transform duration-500 mx-3">
                        <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                            </svg>
                        </span>

                        <h2 class="mt-4 text-lg font-medium text-gray-800 dark:text-white">{{ $t("contact-down-email") }}</h2>
                        <p class="mt-2 text-gray-500 dark:text-gray-400">{{ $t("contact-down-email-des") }}</p>
                        <p class="mt-2 text-blue-500 dark:text-blue-400">info@ngsi.de</p>
                    </div>
                </a>
                <a target="blank"
                    href="https://www.google.com/maps/dir//NGSI+GmbH,+Im+B%C3%BChl+12,+71287+Weissach,+Almanya/@48.824001,8.913726,10z/data=!3m1!5s0x479763736bec2947:0x18db952ec99de954!4m9!4m8!1m0!1m5!1m1!1s0x479763736f21f863:0x6feaa21be1a0d844!2m2!1d8.9137256!2d48.8240012!3e0?hl=tr-TR">
                    <div
                        class="group/item hover:dark:bg-gray-700 hover:bg-gray-300 hover:scale-110 py-3 rounded-lg text-center justify-center flex flex-col items-center transform duration-500 mx-3">
                        <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1110 0z" />
                            </svg>
                        </span>

                        <h2 class="mt-4 text-lg font-medium text-gray-800 dark:text-white">{{ $t("contact-down-office") }}
                        </h2>
                        <p class="mt-2 text-gray-500 dark:text-gray-400 ">{{ $t("contact-down-office-des") }}</p>
                        <p class="mt-2 text-blue-500 dark:text-blue-400">Im Bühl 12, 71287 Weissach-Flacht</p>
                    </div>
                </a>
                <a href="tel:+49 (0) 7152 - 450 80 03">

                    <div
                        class="text-center group/item hover:dark:bg-gray-700 hover:bg-gray-300 hover:scale-110 py-3 rounded-lg justify-center flex flex-col items-center transform duration-500 mx-3">
                        <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80 dark:bg-gray-800">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                            </svg>
                        </span>

                        <h2 class="mt-4 text-lg font-medium text-gray-800 dark:text-white">{{ $t("contact-down-phone") }}</h2>
                        <p class="mt-2 text-gray-500 dark:text-gray-400">{{ $t("contact-down-phone-des") }}</p>
                        <p class="mt-2 text-blue-500 dark:text-blue-400">+49 (0) 7152 - 450 80 03</p>
                    </div>
            </a>
        </div>
    </div>
</section></template>